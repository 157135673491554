import React from "react";
import useUsers from "../../hooks/useUsers";

function Users() {
  const { users, loadingUsers, usersError } = useUsers();
  return (
    <div>
      <h2>Users</h2>
      {users.map((item, index) => {
        console.log(item);
        return (
          <>
            {Object.keys(item).map((subitem, subindex) => {
              return subitem;
            })}
          </>
        );
      })}
    </div>
  );
}

export default Users;
