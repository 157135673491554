import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavbarWrapper from "./components/NavbarWrapper/NavbarWrapper";
import { sidebarConfig, topbarConfig } from "./uiconfig/navbarConfig";

const pagesContext = require.context("./routes", true, /\.js$/);
const pages = pagesContext.keys().map(pagesContext);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path={"/"}
            element={
              <NavbarWrapper config={topbarConfig}>
                <div>Home</div>
              </NavbarWrapper>
            }
          />

          {pages.map((PageComponent, index) => {
            console.log(PageComponent.default);
            return (
              <Route
                key={index}
                exact
                path={`/${PageComponent.default.name}`}
                element={
                  <NavbarWrapper config={topbarConfig}>
                    <NavbarWrapper config={sidebarConfig}>
                      {PageComponent.default()}
                    </NavbarWrapper>
                  </NavbarWrapper>
                }
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
