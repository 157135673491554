import React from "react";
import Navbar from "../Navbar/Navbar";

function NavbarWrapper({ config, children }) {
  const dockDict = {
    top: {
      flexDirection: "column",
      height: "calc(100% - " + config.size + ")",
      width: "100%",
    },
    bottom: {
      flexDirection: "column-reverse",
      height: "calc(100% - " + config.size + ")",
      width: "100%",
    },
    left: {
      flexDirection: "row",
      height: "100%",
      width: "calc(100% - " + config.size + ")",
    },
    right: {
      flexDirection: "row-reverse",
      height: "100%",
      width: "calc(100% - " + config.size + ")",
    },
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: dockDict[config.dock]["flexDirection"],
      }}
    >
      <Navbar dock={config.dock} size={config.size} buttons={config.buttons} />
      <div
        style={{
          backgroundColor: "green",
          height: dockDict[config.dock]["height"],
          width: dockDict[config.dock]["width"],
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default NavbarWrapper;
