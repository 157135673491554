import React from "react";

import NavButton from "../NavButton/NavButton";

function Navbar(props) {
  const dockDict = {
    top: {
      height: props.size,
      width: "100%",
    },
    bottom: {
      height: props.size,
      width: "100%",
    },
    left: {
      height: "100%",
      width: props.size,
    },
    right: {
      height: "100%",
      width: props.size,
    },
  };
  const navbarStyle = {
    backgroundColor: "blue",
    height: dockDict[props.dock]["height"],
    width: dockDict[props.dock]["width"],
  };
  return (
    <div style={navbarStyle}>
      <NavButton title={"Home"} link={"/"} />
      {props.buttons.map((button, index) => {
        return (
          <div key={index}>
            <NavButton title={button.title} link={button.link} />
          </div>
        );
      })}
    </div>
  );
}

export default Navbar;
