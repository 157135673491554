import { pages } from "../util/pages";

export const sidebarConfig = {
  dock: "left", // top, bottom, left, right
  size: "100px",
  collapsable: true,
  buttons: pages.map((page, index) => {
    return { title: page.default.name, link: "/" + page.default.name };
  }),
};

export const topbarConfig = {
  dock: "top", // top, bottom, left, right
  size: "100px",
  collapsable: true,
  buttons: pages.map((page, index) => {
    return { title: page.default.name, link: "/" + page.default.name };
  }),
};
