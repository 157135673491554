import useFetch from "./useFetch";
import { getUsers } from "../api/users";
const useUsers = () => {
  const {
    loading: loadingUsers,
    error: usersError,
    data: users,
  } = useFetch(getUsers);

  return { loadingUsers, usersError, users };
};

export default useUsers;
