import React from "react";
import { useNavigate } from "react-router-dom";

function NavButton({ title, icon, link }) {
  let nav = useNavigate();
  return (
    <div
      onClick={() => {
        nav(link);
      }}
      style={{ cursor: "pointer", border: "1px solid black" }}
    >
      {title}
      {icon}
    </div>
  );
}

export default NavButton;
